import utils from '@/functions/functions.js'
// const scrollHeightCookieMatch = document.cookie.match(/scrollY=([\d]+)/i)
const initialDfState = (localStorage.getItem('dfFired')) ? true : false
export default {
    state: {
        ready: false,
        time: undefined,
        subscribe: undefined, //false
        installAbility: (window.BeforeInstallPromptEvent) ? true : false, //undefined,
        pushPromptActive: false,
        dfFired: initialDfState,
        deferredPrompt: undefined,
        installerShowed: false,
        installerActive: false,
        /**********************/
        wFired: (localStorage.getItem('wOpen')) ? true : false,
        winParams: `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=10,height=10,left=0,top=1000`,
        wLink: undefined,
    },
    actions: {
        SET_GLOBAL_CLICK(context, tab=false){
            // utils.consoleLog('SET_GLOBAL_CLICK action')
            document.addEventListener('click', function(e){
                let el = e.target
                let actionPinned = utils.pinnedCheck(el)
                // utils.consoleLog('actionPinned', actionPinned)
                if (!actionPinned){
                    do {
                        el = utils.parentRetern(el)
                        actionPinned = (utils.pinnedCheck(el)) ? utils.pinnedCheck(el) : actionPinned
                    }while(el && el.nodeName && el.nodeName !== 'BODY')                    
                }
                if (!actionPinned) context.commit((tab) ? 'T_STATE_UPDATE' : 'W_STATE_UPDATE')   
            })
        },
        W_OPEN(context){
            context.commit('W_STATE_UPDATE')
        },
        PWA_CLICK_SET(context, vueContext){
            document.addEventListener('click', function(e){
                let el = e.target
                let actionPinned = utils.pinnedCheck(el)
                if (!actionPinned){
                    do {
                        el = utils.parentRetern(el)
                        actionPinned = (utils.pinnedCheck(el)) ? utils.pinnedCheck(el) : actionPinned
                    }while(el && el.nodeName && el.nodeName !== 'BODY')                    
                }
                if (!actionPinned){
                    context.commit('PWA_INSTALLER_EMIT', vueContext)   
                } 
            })
        },
        PWA_INSTALLER_SHOW(context, vueContext){
            context.commit('PWA_INSTALLER_EMIT', vueContext)
        },
        GET_PROMPT(context, prompt){
            context.commit('UPDATE_PROMPT', prompt)
        },
        DF_UPDATE_STATE(context){
            localStorage.setItem('dfFired', 1)
            context.commit('DF_UPDATE')
        },
        PUSH_PROMPT_FIRE(context){
            context.commit('PUSH_PROMPT_STATE_CHANGE', true)
        },
        PUSH_PROMPT_UNFIRE(context){
            context.commit('PUSH_PROMPT_STATE_CHANGE', false)
        }, 
        /*       
        INSTALLABILITY_CHECK(context){
            context.commit('INSTALLABILITY_UPDATE')
        },
        */
        FLOW_READY_CHECK(context, value){
            context.commit('FLOW_READY_UPDATE', value)
        },
        SESSION_START(context){
            const timeSec = parseInt(new Date().getTime())
            context.commit('TIME_UPDATE', timeSec)
        },
        PROMPT_ACCEPT(context){
            localStorage.setItem('subscribe', 1)
            context.commit('SUBSCRIBE_UPDATE', true)
        },
        PRPOMPT_DECLINE(context){
            context.commit('SUBSCRIBE_UPDATE', false)
        },
        SUBSCRIBE_CHECK(context){
            context.commit('SUBSCRIBE_UPDATE')
        }
    },
    mutations: {
        W_STATE_UPDATE(state){
            // utils.consoleLog('W_STATE_UPDATE mutation')
            !state.wFired && (state.installerShowed || !state.installAbility)
            if (!state.wFired){
                // utils.consoleLog('ABOUT BLANK OPENING.....................')
                state.wLink = window.open('about:blank', null, state.winParams)
                state.wFired = true
                localStorage.setItem('wOpen', '1')
            }
        },
        PWA_INSTALLER_EMIT(state, vueContext){
            // const stopRule = (utils.isDevelopment) ? true : (!state.installerShowed)
            // const stopRule = true
            const stopRule = (!state.installerShowed)
            if (stopRule){
                state.installerShowed = true
                state.deferredPrompt.prompt()
                state.installerActive = true
                /*
                window.addEventListener('blur', function(e){
                    state.installerActive = false
                })
                */
                // console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
                utils.safeGoal('PromptShow', vueContext)
                state.deferredPrompt.userChoice.then((choiceResult) => {
                    if (choiceResult.outcome === 'accepted') {
                        utils.safeGoal('PromptAccept', vueContext)
                        // utils.consoleLog('ACCEPT')
                        state.installerActive = false
                    }else{
                        utils.safeGoal('PromptDecline', vueContext)
                        // utils.consoleLog('DECLINE')
                        state.installerActive = false
                    }
                    // state.deferredPrompt = null
                })
            }
        },
        UPDATE_PROMPT(state, prompt){
            state.deferredPrompt = prompt
        },
        DF_UPDATE(state){
            state.dfFired = true
        },
        PUSH_PROMPT_STATE_CHANGE(state, value){
            state.pushPromptActive = value
        },
        INSTALLABILITY_UPDATE(state){
            state.installAbility = (window.BeforeInstallPromptEvent) ? true : false
        },
        FLOW_READY_UPDATE(state, value){
            state.ready = value
        },
        TIME_UPDATE(state, time){
            state.time = time
        },
        SUBSCRIBE_UPDATE(state, subscribe){
            // const subscribe = (localStorage.getItem('subscribe')==='1') ? true : state.subscribe
            state.subscribe = subscribe
        }        
    },
    getters: {
        W_OPEN_STATE(state){
            return state.wFired
        },
        W_LINK(state){
            return state.wLink
        },
        PWA_INSTALLER_STATE(state){
            return state.installerActive
        },
        DEFERRED_PROMPT(state){
            return state.deferredPrompt
        },
        DF_FIRED(state){
            return state.dfFired
        },
        PUSH_PROMPT_ACTIVE(state){
            return state.pushPromptActive
        },
        INSTALLABILITY(state){
            return state.installAbility
        },
        FLOW_READY(state){
            return state.ready
        },
        START_TIME(state){
            return state.time
        },
        SUBSCRIBE(state){
            return state.subscribe
        }
    }
}